const videos = [
    //{
    // 	id: 1,
    // 	title: "Uptight (Official Lyric Video)",
    // 	link: "https://www.youtube.com/watch?v=qZwLYFU-tXI"
    //},
    //{
    // 	id: 2,
    // 	title: "Stoned Session",
    // 	link: "https://www.youtube.com/watch?v=ZCWWFoh5CCc"
    //},
    //{
    // 	id: 3,
    // 	title: "Going Nowhere (Official Video)",
    // 	link: "https://www.youtube.com/watch?v=r-m2uSizplw"
    //},
    {
        id: 4,
        title:"Loyal Gun ao vivo no programa Fique Ligado (TV Brasil), 31.07.2019",
        link: "https://www.youtube.com/embed/HJokIVYRtRE"
    },    
    {
        id: 5,
        title: "Loyal Gun - Come Back (Official Video)",
        link: "https://www.youtube.com/embed/b8t6LZvq5k8"
    },
    {
        id: 5,
        title: "Loyal Gun - Ao Vivo no Castelo",
        link: "https://www.youtube.com/embed/bGa7yJBnwMQ"
    },
    {
        id: 6,
        title: "Loyal Gun - The Golden Ocean (Official Video)",
        link: "https://www.youtube.com/embed/mWNuORfi0ow"
    },
    {
        id: 7,
        title: "Loyal Gun - Bring Back Your Beat (Official Video)",
        link: "https://www.youtube.com/embed/9ZyZB-wOV9w"
    },
]

export default videos
