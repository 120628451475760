const social = [
    {
        id: 1,
        text: "loyal gun",
        icon: ["fab", "spotify"],
        link: "https://open.spotify.com/intl-pt/artist/1bVsmkvRtAVtVJyk6tfHX9?si=XAAHau2tRJWNRehRagcwQg"
    },
    {
        id: 2,
        text: "loyal gun",
        icon: ["fab", "deezer"],
        link: "https://deezer.page.link/fFYCYbFL5LGk3nXR6"
    },
    {
        id: 3,
        text: "loyalgun",
        icon: ["fab", "apple"],
        link: "https://music.apple.com/us/artist/loyal-gun/1047240110",
        
    },
    {
        id: 4,
        text: "loyalgun",
        icon: ["fab", "amazon"],
        link: "https://www.amazon.com/music/player/artists/B019K0ENDA/loyal-gun",
        
    },
    {
        id: 5,
        text: "loyalgun",
        icon: ["fab", "youtube"],
        link: "https://www.youtube.com/@loyalgun",
        
    },
    {
        id: 6,
        text: "loyalgun",
        icon: ["fab", "bandcamp"],
        link: "https://loyalgun.bandcamp.com",
        
    },
    {
        id: 7,
        text: "loyalgun",
        icon: ["fab", "soundcloud"],
        link: "https://soundcloud.com/loyalgun",
        
    }
]

export default social
