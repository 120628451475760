const contactLinks = [  
    {
        id: 1,
        name: "Coletivo Lança",
        link: "https://coletivolanca.com.br/js_artist/loyal-gun/"
    },
    {
        id: 2,
        name: "Spotify",
        link: "https://open.spotify.com/intl-pt/artist/1bVsmkvRtAVtVJyk6tfHX9?si=XAAHau2tRJWNRehRagcwQg"
    },
    {
        id: 3,
        name: "Deezer",
        link: "https://deezer.page.link/fFYCYbFL5LGk3nXR6"
    },
    {
        id: 4,
        name: "Apple Music",
        link: "https://music.apple.com/us/artist/loyal-gun/1047240110"
    },
    {
	id: 5,
	name: "Amazon Music",
	link: "https://www.amazon.com/music/player/artists/B019K0ENDA/loyal-gun"
    },
        {
	id: 6,
	name: "Tidal",
	link: "https://tidal.com/browse/artist/7303871"
    },
    {
        id: 7,
        name: "YouTube",
        link: "https://www.youtube.com/@loyalgun"
    },
    {
        id: 8,
        name: "Bandcamp",
        link: "https:/loyalgun.bandcamp.com"
    },
    {
        id: 9,
        name: "Soundcloud",
        link: "https://soundcloud.com/loyalgun"
    },
        {
	id: 10,
	name: "TikTok",
	link: "https://www.tiktok.com/@loyalgunband/"
    },
    {
        id: 11,
        name: "loyalgunofficial@gmail.com",
        link: "mailto:loyalgunofficial@gmail.com",
        contato: true
    },
    {
        id: 12,
        name: "Facebook",
        link: "https://www.facebook.com/loyalgun",
        contato: true
    },
    {
        id: 13,
        name: "Instagram",
        link: "http://instagram.com/loyalgun",
        contato: true
    },
    {
        id: 14,
        name: "Twitter/X",
        link: "https://twitter.com/loyalgun",
        contato: true
    },
]

export default contactLinks
